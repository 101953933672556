import Classes from "./PlayInfo.module.css";
import { Row, Col } from "antd";
import PlayImg from "../../assets/play.jpg";
const PlayInfo = () => {
  return (
    <div>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>Play Info</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={10}
          xl={12}
          xxl={14}
          className={Classes.InfoPanel}
        >
          <h2>WILD FLOWER PLAY GROUND</h2>
          <p>
            Our indoor play ground is especially designed for babies, toddlers
            and pre-schoolers (0-5 years old), to inspire them, challenge them
            and let their imagination take flight. We are very attentive to
            safety for children in our design of the area, as it is our wish to
            make the parents feel comfortable and at ease while the children
            have fun. The play area is secured with child-safe gates and clear
            vision for parents to see their children while seated with their cup
            of coffee. There are different corners for children to play with
            each other, as well as interact with their parents. The equipment is
            chosen specifically for small children to create a wondrous,
            exciting and active learning environment. There are no giant slides
            or equipment in motion. Instead, we offer a unique environment that
            provides both active-play and imagination-based play activities. We
            also have a special quiet library in the sky especially made for
            those we love to read and enjoy a calm atmosphere while they let
            their mind wander through the most exciting adventures.
          </p>
          <p>
            We care for the adults as much as the children. We understand the
            struggles of everyday parenting and want to provide a sanctuary for
            parents to enjoy a much-deserved mental break from their daily
            stress where you can recharge and refresh. Our café menu is
            available for both play centre patrons and walk-in customers.
          </p>
          <h2>RULES</h2>
          <p>
            Please remove your shoes and WEAR SOCKS ONLY in the Wild Flower
            playground. (Socks are available for purchase)
          </p>
          <p>No food or drink is allowed beyond the butterfly door.</p>
          <p>Smoking and alcohol are prohibited on the premises.</p>
          <p>
            Please look after your personal belongings, Dancing Dandelion
            accepts no responsibility for lost or stolen articles.
          </p>
          <p>
            Dancing Dandelion staff provide general supervision of the Wild
            Flower playground. All children are at the responsibility of their
            caregiver/ accompanying adult at all times.
          </p>
          <h2>For safety purposes</h2>
          <p>
            <b>Please do not run inside the playground</b>
          </p>
          <p>
            <b>
              Children must remove jewelry and any other sharp objects before
              entering
            </b>
          </p>
          <p>
            <b>
              The Wild Flower playground is designed for ALL children to play
              and enjoy. Upon entry, please ensure appropriate behaviour and
              safe practices, so that everyone can feel safe and have fun.
            </b>
          </p>
          <p>
            <b>
              Taking photos and videos of your accompanying child having fun is
              allowed. However, please avoid photographing or filming other
              children without permission.
            </b>
          </p>
          <p>Thank you for adhering to the rules, have FUN!</p>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={12}
          xl={10}
          xxl={8}
          className={Classes.InfoImgPanel}
        >
          <img src={PlayImg} alt="About us image" className={Classes.InfoImg} />
        </Col>
      </Row>
    </div>
  );
};

export default PlayInfo;
