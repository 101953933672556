import Classes from "./Footer.module.css";
import { Row, Col, Divider } from "antd";
import AddressIcon from "../../assets/icons/footer/location.png";
import EmailIcon from "../../assets/icons/footer/email.png";
import WeekdaysIcon from "../../assets/icons/footer/calendar-date.png";
import WeekendIcon from "../../assets/icons/footer/weekend.png";
import FbIcon from "../../assets/icons/footer/fb.png";
import InsIcon from "../../assets/icons/footer/ins.png";
const Footer = () => {
  return (
    <div className={Classes.Footer}>
      <Row className={Classes.FooterTitleLayer}>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} />
        <Col xs={23} sm={10} md={10} lg={8} xl={6} xxl={4}>
          <span className={Classes.ContactTitle}>Contact Us</span>
          <div>
            <img
              alt="address_icon"
              src={AddressIcon}
              className={Classes.IconStyle}
            />
            <span className={Classes.IconText}>
              323 Whitehorse Road, Balwyn
            </span>
          </div>
          <Divider />
          <div>
            <img
              alt="email_icon"
              src={EmailIcon}
              className={Classes.IconStyle}
            />
            <span className={Classes.IconText}>
              info@dancingdandelion.com.au
            </span>
          </div>
          <Divider />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} />
        <Col xs={23} sm={11} md={10} lg={8} xl={7} xxl={5}>
          <span className={Classes.OpenTitle}>Opening Hours</span>
          <div>
            <img
              alt="weekdays_icon"
              src={WeekdaysIcon}
              className={Classes.IconStyle}
            />
            <span className={Classes.IconText}>Mon - Fri : 9am - 3pm</span>
          </div>
          <Divider />
          <div>
            <img
              alt="weekend_icon"
              src={WeekendIcon}
              className={Classes.IconStyle}
              style={{ width: "20px" }}
            />
            <span className={Classes.IconText}>
              Sat - Sun : Private party bookings only
            </span>
          </div>
          <Divider />
        </Col>
        <Col xs={2} sm={14} md={10} lg={8} xl={3} xxl={9} />
        <Col
          xs={20}
          sm={9}
          md={10}
          lg={8}
          xl={5}
          xxl={3}
          style={{ textAlign: "right" }}
        >
          <div style={{ height: "80%" }} />
          <span className={Classes.FollowTitle}>Follow Us:</span>
          <a href="https://www.facebook.com/Dancingdandelion.au">
            <img
              alt="facebook_icon"
              src={FbIcon}
              className={Classes.SocialIcon}
            />
          </a>
          <a href="https://www.instagram.com/dancing.dandelion_au/">
            <img
              alt="Instagram_icon"
              src={InsIcon}
              className={Classes.SocialIcon}
            />
          </a>
        </Col>
      </Row>

      <Row className={Classes.FooterMiddleLayer} align="middle">
        <Col span={1} />
        <Col xs={22} sm={13} md={10} lg={8} xl={6} xxl={5}>
          <span className={Classes.CopyText}>
            © 2022 Dancing Dandelion. All rights reserved.
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
