import Classes from "./Home.module.css";
import Carousel from "react-bootstrap/Carousel";
import Slider1 from "../../assets/slider/1.jpg";
import Slider2 from "../../assets/slider/2.jpg";
import { Row, Col, Button } from "antd";
import Aux from "../../hoc/aux/Aux";
import WelcomeBgImg from "../../assets/bg/wellcome-bg.jpg";
import exIcon1 from "../../assets/icons/home/ex-icon1.png";
import exIcon2 from "../../assets/icons/home/ex-icon2.png";
import exIcon3 from "../../assets/icons/home/ex-icon3.png";
import exIcon4 from "../../assets/icons/home/ex-icon4.png";

const Home = () => {
  return (
    <Aux>
      <div className={Classes.Banner}>
        <Carousel indicators={false}>
          <Carousel.Item>
            <div className={Classes.SlideLayout}>
              <img
                src={Slider1}
                alt="First slide"
                className={Classes.SlideStyle}
              />
              <div className={Classes.SlideOneText}>
                A Children's cafe where adults matter
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={Classes.SlideLayout}>
              <img
                src={Slider2}
                alt="Second slide"
                className={Classes.SlideStyle}
              />
              <div className={Classes.SlideTwoText}>
                <div className={Classes.SlideTwoTextTitle}>Our Space</div>
                <div className={Classes.SlideTwoTextContent}>
                  made for adults as well as children
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className={Classes.BannerBillboard}>
          <Row gutter={[0, 16]} justify="center">
            {/* <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3} /> */}
            <Col
              xs={22}
              sm={22}
              md={7}
              lg={7}
              xl={6}
              xxl={6}
              className={Classes.BannerBillboardPanel1}
            >
              <h4>Our Cafe</h4>
              <p>
                Quality and simplicity are what we uphold at Dancing Dandelion.
                As our Grandma used to say, if you share, share only your best.
                Our coffee beans are aromatic and smooth, Our teas come from T2.
                Our breads are made inhouse freshly every day, topped with
                Dancing Dandelion special spreads.
              </p>
            </Col>
            <Col
              xs={22}
              sm={22}
              md={7}
              lg={7}
              xl={6}
              xxl={6}
              className={Classes.BannerBillboardPanel2}
            >
              <h4>Parties</h4>
              <p>
                Our play ground is available for party hire on the weekends with
                customisable options. Get in touch with us for more information!
              </p>
            </Col>
            <Col
              xs={22}
              sm={22}
              md={7}
              lg={7}
              xl={6}
              xxl={6}
              className={Classes.BannerBillboardPanel3}
            >
              <h4>Play Area</h4>
              <p>
                Our indoor play ground is designed for babies, toddlers and
                pre-schoolers (0-5 years old), to inspire them, challenge them
                and let their imagination take flight. We are very attentive to
                safety for children in our design of the area, as it is our wish
                to make the parents feel comfortable and at ease while the
                children have fun.{" "}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div className={Classes.EmptyBanner} />
      <Row>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.WelcomeBox}>
            <div className={Classes.WelcomeTextPanel}>
              <h3>About Us</h3>
              <p>
                Having children of our own, we’ve always been on a search for a
                place where children can play safely while parents can find some
                rest and be nourished with appetising food all in one place. It
                is our vision to provide exactly what parents have been
                searching for.
              </p>
              <Button
                type="primary"
                shape="round"
                danger
                className={Classes.WelcomeBtn}
              >
                <b>More</b>
              </Button>
            </div>
            <img
              src={WelcomeBgImg}
              alt="Welcome image"
              className={Classes.WelcomeImg}
            />
          </div>
        </Col>
      </Row>
      <div className={Classes.ExpectSection}>
        <div className={Classes.ExpectSectionTitle}>We Value</div>
        <p>
          "If you share, share only your best" Our Grandma's words ring in our
          ears.
        </p>
        <Row gutter={[0, 16]} justify="center">
          <Col
            xs={22}
            sm={20}
            md={10}
            lg={10}
            xl={6}
            xxl={6}
            className={Classes.ExIconPanel}
          >
            <img src={exIcon1} alt="ex-icon1" className={Classes.ExIcon} />
            <div className={Classes.ExIconTextPanel}>
              <h4>Connect</h4>
              <p>
                Providing you an opportunity to connect with your child as well
                as others.
              </p>
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={10}
            lg={10}
            xl={6}
            xxl={6}
            className={Classes.ExIconPanel}
          >
            <img src={exIcon2} alt="ex-icon2" className={Classes.ExIcon} />
            <div className={Classes.ExIconTextPanel}>
              <h4>Fun</h4>
              <p>
                Enabling the child to be inspired and have fun in a safe and
                clean environment.
              </p>
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={10}
            lg={10}
            xl={6}
            xxl={6}
            className={Classes.ExIconPanel}
          >
            <img src={exIcon3} alt="ex-icon3" className={Classes.ExIcon} />
            <div className={Classes.ExIconTextPanel}>
              <h4>Recharge</h4>
              <p>
                Creating a santuary for you to have some "me-time" in the busy
                bustle of life.
              </p>
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={10}
            lg={10}
            xl={6}
            xxl={6}
            className={Classes.ExIconPanel}
          >
            <img src={exIcon4} alt="ex-icon4" className={Classes.ExIcon} />
            <div className={Classes.ExIconTextPanel}>
              <h4>Nourish</h4>
              <p>
                Using only fresh and quality ingredients, we serve food from our
                heart to a happy stomach.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Aux>
  );
};

export default Home;
