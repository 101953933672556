import { Space, Table, Tag } from "antd";
const columns = [
  {
    title: "Booking Id",
    dataIndex: "id",
    key: "id",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Caller Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Booking Type",
    dataIndex: "types",
    key: "types",
    render: (_, { types }) => (
      <>
        {types.map((type) => {
          let color = "#CB5A9A";
          if (type === "Day-to-day booking") {
            color = "#29BBB8";
          }
          return (
            <Tag color={color} key={type}>
              {type.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Party Size",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Quotation price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Request received date",
    dataIndex: "received_date",
    key: "received_date",
  },
  {
    title: "Request due date",
    dataIndex: "due_date",
    key: "due_date",
  },
];
const data = [
  {
    id: "#1123412444",
    name: "John Brown",
    contact: "0431 000 123",
    types: ["Party booking"],
    size: "4A+15C",
    price: "$343.41",
    received_date: "06 July 23",
    due_date: "16 July 23",
  },
  {
    id: "#1123412445",
    name: "Ty Louis",
    contact: "0431 000 333",
    types: ["Day-to-day booking"],
    size: "4A+2C",
    price: "$70.50",
    received_date: "06 July 23",
    due_date: "12 July 23",
  },
];

const PendingBookings = () => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      style={{ marginTop: "24px" }}
      rowClassName={(_, index) => {
        let className = "odd-row";
        if (index % 2 === 1) className = "even-row";
        return className;
      }}
    />
  );
};

export default PendingBookings;
