import { Row, Col } from "antd";
import Classes from "./Party.module.css";
const Party = () => {
  return (
    <div>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>Parties</div>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={10}
          xl={12}
          xxl={14}
          className={Classes.InfoPanel}
        >
          <p>
            Dancing Dandelion is available on Saturdays and Sundays for private
            party bookings.
          </p>
          <p>
            We offer exclusive use of our entire center as well as customizable
            options to take the hassle out of your organization. Please get in
            touch with us via email <b>info@dancingdandelion.com.au</b> for
            further discussion. Thank you!
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Party;
