import React from "react";
import {
  EditOutlined,
  HomeOutlined,
  FormOutlined,
  TeamOutlined,
  ReadOutlined,
  FileSearchOutlined,
  SoundOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  SmileTwoTone,
  DownOutlined,
  BellFilled,
} from "@ant-design/icons";
import { Layout, Menu, Button, Dropdown, Badge } from "antd";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/slices/auth-slice";
import { useNavigate, Link, Route, Routes } from "react-router-dom";
import ManageIcon from "../../assets/icons/admin/management.png";
import Classes from "./DashboardAdmin.module.css";
import UserManagement from "./user_management/UserManagement";
import CurrentOrders from "./orders/current/CurrentOrder";
import HistoryOrders from "./orders/history/HistoryOrder";
import ContentManagement from "./content_management/ContentManagement";
import DashboardAdmin_Main from "../../component/admin/dashboard/Dashboard";
import BookingManagement from "../../component/admin/booking/BookingManagement";
const { Header, Content, Footer, Sider } = Layout;

const DashboardAdmin = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const dispatch = useDispatch();
  //   const current = useSelector((state) => state.dashboard.current);
  //   const myEmail = localStorage.getItem("email");
  const handleClick = (e) => {
    //     dispatch(dashboardActions.navTo(e.key));
  };

  const logout = () => {
    dispatch(authActions.logout());
    navigate("/home");
  };

  const dropdown_items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="">
          1st menu item
        </a>
      ),
    },
  ];
  const items = [
    {
      label: (
        <Link to="/admin-dashboard" style={{ textDecoration: "None" }}>
          Dashboard
        </Link>
      ),
      key: "dashboard",
      icon: <HomeOutlined />,
    },
    {
      label: (
        <Link
          to="/admin-dashboard/booking-management"
          style={{ textDecoration: "None" }}
        >
          Booking Management
        </Link>
      ),
      key: "book-manage",
      icon: <FormOutlined />,
    },
    {
      label: (
        <Link
          to="/admin-dashboard/member-management"
          style={{ textDecoration: "None" }}
        >
          Member Management
        </Link>
      ),
      key: "member-manage",
      icon: <TeamOutlined />,
    },
    {
      label: (
        <Link
          to="/admin-dashboard/receipe-management"
          style={{ textDecoration: "None" }}
        >
          Receipe Management
        </Link>
      ),
      key: "receipe-manage",
      icon: <ReadOutlined />,
    },

    {
      label: (
        <Link
          to="/admin-dashboard/quotation"
          style={{ textDecoration: "None" }}
        >
          Quotation
        </Link>
      ),
      key: "quotation",
      icon: <FileSearchOutlined />,
    },
    {
      label: (
        <Link
          to="/admin-dashboard/promo-hub"
          style={{ textDecoration: "None" }}
        >
          Promo Hub
        </Link>
      ),
      key: "promo",
      icon: <SoundOutlined />,
    },
    {
      label: "OTHERS",
      type: "group",
    },
    {
      label: "Web CMS",
      icon: <EditOutlined />,
      key: "content-manage",
      children: [
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/home"
              style={{ textDecoration: "None" }}
            >
              Home
            </Link>
          ),
          key: "content-home",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/about"
              style={{ textDecoration: "None" }}
            >
              About Us
            </Link>
          ),
          key: "content-about",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/info"
              style={{ textDecoration: "None" }}
            >
              Play Info
            </Link>
          ),
          key: "content-info",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/cafe"
              style={{ textDecoration: "None" }}
            >
              Cafe
            </Link>
          ),
          key: "content-cafe",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/booking"
              style={{ textDecoration: "None" }}
            >
              Booking
            </Link>
          ),
          key: "content-booking",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/parties"
              style={{ textDecoration: "None" }}
            >
              Parties
            </Link>
          ),
          key: "content-parties",
        },
        {
          label: (
            <Link
              to="/admin-dashboard/content-management/contact"
              style={{ textDecoration: "None" }}
            >
              Contact Us
            </Link>
          ),
          key: "content-contact",
        },
      ],
    },
    {
      label: (
        <Link to="/admin-dashboard/accounts" style={{ textDecoration: "None" }}>
          Accounts
        </Link>
      ),
      key: "accounts",
      icon: <UserOutlined />,
    },
    {
      label: (
        <Link to="/admin-dashboard/helps" style={{ textDecoration: "None" }}>
          Helps
        </Link>
      ),
      key: "helps",
      icon: <QuestionCircleOutlined />,
    },
  ];

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={250}
        style={{ backgroundColor: "#32256A" }}
      >
        <div>
          <div className={Classes.Logo}>
            <p style={{ paddingLeft: "24px" }}>Dancing Dandelion</p>
          </div>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={["dashboard"]}
          items={items}
          theme="dark"
          style={{
            height: "90%",
            borderRight: 0,
            backgroundColor: "#32256A",
          }}
        />
        <Button onClick={logout} style={{ width: "100%" }}>
          Log out
        </Button>
      </Sider>
      <Layout style={{ backgroundColor: "white" }}>
        <Header
          style={{
            padding: 0,
            backgroundColor: "white",
            borderBottom: "1px solid #C8CBD9",
            height: "64px",
          }}
        >
          <div
            style={{
              float: "right",
              marginRight: "32px",
              lineHeight: "64px",
            }}
          >
            <SmileTwoTone
              style={{
                fontSize: "24px",
                marginRight: "12px",
              }}
              twoToneColor="red"
            />

            <Dropdown
              menu={{
                items: dropdown_items,
              }}
            >
              <a
                onClick={(e) => e.preventDefault()}
                style={{ textDecoration: "None", color: "black" }}
              >
                <span style={{ marginRight: "14px", fontSize: "14px" }}>
                  Howie
                </span>
                <DownOutlined />
              </a>
            </Dropdown>

            <Badge count={3}>
              <BellFilled
                style={{
                  fontSize: 20,
                  color: "#B0C3CC",
                  marginLeft: "45px",
                }}
              />
            </Badge>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            minHeight: "750px",
          }}
        >
          <div
            style={{
              padding: 24,
            }}
          >
            <Routes>
              <Route path="/" element={<DashboardAdmin_Main />} />
              <Route
                path="/booking-management"
                element={<BookingManagement />}
              />
              <Route path="/orders/history" element={<HistoryOrders />} />
              <Route
                path="/content-management/home"
                element={<ContentManagement title="Home" />}
              />
              <Route
                path="/content-management/about"
                element={<ContentManagement title="About Us" />}
              />
              <Route
                path="/content-management/info"
                element={<ContentManagement title="Play Information" />}
              />
              <Route
                path="/content-management/cafe"
                element={<ContentManagement title="Cafe" />}
              />
              <Route
                path="/content-management/booking"
                element={<ContentManagement title="Booking" />}
              />
              <Route
                path="/content-management/parties"
                element={<ContentManagement title="Parties" />}
              />
              <Route
                path="/content-management/contact"
                element={<ContentManagement title="Contact Us" />}
              />
              <Route
                path="/content-management/"
                element={<ContentManagement title="Home" />}
              />
              <Route path="/" element={<UserManagement />} />
            </Routes>
          </div>
        </Content>
        {/* <Footer
          style={{
            textAlign: "center",
          }}
        >
          Ant Design ©2018 Created by Ant UED
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default DashboardAdmin;
