import Classes from "./Login.module.css";
import { Form, Input, Button, Row, Col } from "antd";
import { PRIMARY_BLUE } from "../../utils/Constant";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    let sendData = {
      email: values.email,
      password: values.password,
    };

    let customerEmail = "customer@jesksupport.com.au";
    let adminEmail = "admin@jesksupport.com.au";

    if (values.email === customerEmail && values.password === "123456") {
      dispatch(authActions.isCustomerLogin());
      navigate("/customer-dashboard");
    } else if (values.email === adminEmail && values.password === "123456") {
      dispatch(authActions.isAdminLogin());
      navigate("/admin-dashboard");
    }

    // axios.post(API_URL.login, sendData).then((res) => {
    //   if (res.status === 200) {
    //     setUserEmail(values.email);
    //     navigate("/dashboard");
    //   } else {
    //     message.error(res.data.message);
    //   }
    // });
  };

  return (
    <>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>Log in</div>
        </Col>
      </Row>

      <div className={Classes.signup_form}>
        {/* <div style={{ minHeight: "120px", paddingLeft: "20%" }}>
          <h2>Login to Dashboard</h2>
        </div> */}
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              { type: "email", message: "Please input a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 6,
                message: "The password needs at least 6 characters!",
              },
            ]}
          >
            <Input.Password autoComplete="off" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 16,
            }}
          >
            <Button
              style={{
                width: "100%",
                border: "None",
                marginTop: "20px",
                background: PRIMARY_BLUE,
              }}
              type="primary"
              htmlType="submit"
              size="large"
            >
              <b> Login</b>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Login;
