import { Calendar, Row, Col, TimePicker, Form, Input, Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Classes from "./Booking.module.css";
const { RangePicker } = TimePicker;
const Booking = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 22,
    },
  };
  // set time picker format
  const timeFormat = "HH:mm";

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <>
      {!props.isDashboard ? (
        <Row className={Classes.Banner}>
          <Col span={1} />
          <Col span={22}>
            <div className={Classes.Title}>Booking</div>
          </Col>
        </Row>
      ) : null}
      <Row className={Classes.MainLayout}>
        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} />
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={10}
          xl={10}
          xxl={10}
          className={Classes.CalenderLayout}
        >
          {" "}
          <h4>Select a date</h4>
          <Calendar
            onPanelChange={onPanelChange}
            fullscreen={false}
            className={Classes.Calender}
          />
        </Col>

        <Col xs={22} sm={22} md={22} lg={12} xl={12} xxl={12}>
          <h4 className={Classes.BookTitle}>Booking detail</h4>
          <Form
            {...formItemLayout}
            layout="vertical"
            form={form}
            className={Classes.DetailPanel}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                },
              ]}
            >
              <Input placeholder="Please input your name" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone",
                  type: "number",
                },
              ]}
            >
              <Input placeholder="Please input your phone" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Please input your email" />
            </Form.Item>
            <Form.Item
              label="Time Picker"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please select a time",
                },
              ]}
            >
              <RangePicker
                style={{ width: "80%" }}
                size="large"
                format={timeFormat}
              />
            </Form.Item>
            <Form.Item label="Message" name="message">
              <Input.TextArea allowClear rows={6} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 8,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ marginTop: "10%", fontSize: "18px" }}
                //  loading={isLoading}
              >
                {isLoading ? <b> Booking</b> : <b> Book</b>}
              </Button>
              <Button
                type="primary"
                onClick={onReset}
                danger
                style={{ marginLeft: "20px", fontSize: "18px" }}
                size="large"
              >
                <b>Clear</b>
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
};

export default Booking;
