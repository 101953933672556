import React from "react";
import Classes from "./Layout.module.css";
import Toolbar from "../../containers/toolbar/Toolbar";
import Footer from "../../containers/footer/Footer";

const Layout = (props) => {
  return (
    <div className={Classes.MainLayout}>
      <Toolbar />
      <main className={Classes.Content}>{props.children}</main>
      <Footer style={{ position: "absolute", bottom: "0px" }} />
    </div>
  );
};

export default Layout;
