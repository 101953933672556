import { Button, Tabs, Badge, Segmented, Input } from "antd";
import { SisternodeOutlined } from "@ant-design/icons";
import PendingBookings from "./pending/PendingBookings";
import UpcomingBookings from "./upcoming/UpcomingManagement";
import UpcomingManagementCalendar from "./upcoming/UpcomingManagementCalendar";
import AllBookingHistories from "./all/AllBookings";
import { useState } from "react";
import { PRIMARY_PURPLE } from "../../../utils/Constant";
const { Search } = Input;

const BookingManagement = () => {
  const [isTableMode, setIsTableMode] = useState(true);
  const switchUpcomingMode = () => {
    setIsTableMode(!isTableMode);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: (
        <Badge count={2} offset={[7, 0]}>
          Pending bookings
        </Badge>
      ),
      children: <PendingBookings />,
    },
    {
      key: "2",
      label: (
        <Badge count={5} offset={[7, 0]}>
          Upcoming bookings
        </Badge>
      ),
      children: (
        <>
          <div style={{ width: "100%", height: "64px" }}>
            <Button
              style={{
                background:
                  "linear-gradient(88.45deg, #7B61FF 4.02%, rgba(64, 169, 255, 0.55) 129.77%)",
                borderRadius: "20px",
                height: "32px",
                color: "white",
                margin: "8px 0 16px 0",
                float: "right",
              }}
              onClick={switchUpcomingMode}
            >
              <b>View In Canlendar Mode</b>
            </Button>
          </div>

          {isTableMode ? <UpcomingBookings /> : <UpcomingManagementCalendar />}
        </>
      ),
    },
    {
      key: "3",
      label: (
        <Badge count={0} offset={[5, 0]}>
          All booking histories
        </Badge>
      ),
      children: (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              margin: "7px 0 14px 0",
              justifyContent: "space-between",
            }}
          >
            <Search
              placeholder="input search text"
              // onSearch={onSearch}
              style={{
                width: 500,
              }}
            />
            <div>
              <span style={{ marginRight: "10px" }}>View by:</span>
              <Segmented
                options={["Weekly", "Monthly", "Yearly", "All"]}
                defaultValue="Weekly"
                style={{
                  color: PRIMARY_PURPLE,
                }}
              />
            </div>
          </div>
          <AllBookingHistories />
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <span
          style={{
            fontWeight: "800",
            fontSize: "24px",
            lineHeight: "12px",
            color: "#343434",
          }}
        >
          Booking Management Page
        </span>
        <Button icon={<SisternodeOutlined />} style={{ marginLeft: "24px" }}>
          {" "}
          New Booking
        </Button>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        style={{ marginTop: "12px" }}
      />
    </div>
  );
};

export default BookingManagement;
