import Booking from "../../booking/Booking";

const NewBooking = () => {
  return (
    <div style={{ background: "white", padding: 12 }}>
      <Booking isDashboard={true} />
    </div>
  );
};

export default NewBooking;
