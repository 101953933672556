import { Form, Input, Row, Col, Button } from "antd";
import Classes from "./Contact.module.css";

const { TextArea } = Input;

const Contact = () => {
  return (
    <div>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>Contact Us</div>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col xxl={12} xl={14} lg={18} md={18} sm={22} xs={22}>
          <h3 style={{ marginTop: "30px", marginBottom: "20px" }}>
            Leave a message
          </h3>
          <Form>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Contact number" />
            </Form.Item>

            {/* <Form.Item rules={[{ required: true }]}>
              <Select placeholder="Please select a category">
                <Option value="Technology">Information technology</Option>
                <Option value="Construction">Construction</Option>
                <Option value="Security">Security</Option>
                <Option value="Market">Market</Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your message",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Message" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" size="large">
                <b>Send</b>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
