import Classes from "./Cafe.module.css";
import { Row, Col } from "antd";
import PlayImg from "../../assets/food.jpg";
import QrImg from "../../assets/qr-code.png";
const menu_link =
  "https://www.canva.com/design/DAFDff2B3Gc/yXRJP3KJ0Cnr2xjKUSxkaQ/view?utm_content=DAFDff2B3Gc&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu";

const Cafe = () => {
  return (
    <div>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>Cafe</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={10}
          xl={12}
          xxl={14}
          className={Classes.InfoPanel}
        >
          <h2>Café</h2>
          <p>
            Quality and simplicity are what we uphold at Dancing Dandelion. As
            our Grandma used to say, if you share, share only your best. Our
            coffee beans are aromatic and smooth, Our teas come from T2. Our
            breads are made inhouse freshly every day, topped with Dancing
            Dandelion special spreads or toasted with your favourite sandwich
            fillings. We also have homemade popcorn chicken, that goes
            incredibly well with our homemade fruit tea. As for baked goods,
            they come freshly from our oven daily to satisfy your sweet tooth,
            yummy scones and cupcakes are our children’s favourite. We also
            offer a range of made-to-order hot and cold beverages, including
            fresh juice. We source the ingredients locally, ensuring only
            freshness and finest quality.
          </p>
          <p>
            We have a separate seating area for walk-in patrons who only wishes
            to dine and not use the play area facilities. Takeaways are also
            available.
          </p>

          <p>
            As we have daily special bakes and seasonal specials, check out our
            social media posts for the latest updates.
          </p>
          <p>
            You can view our menu by clicking {"->"}{" "}
            <a href={menu_link}>HERE</a>
          </p>
          <p>Alternatively you can scan the qr code below</p>
          <img src={QrImg} alt="QR code" className={Classes.QRImg} />
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={12}
          xl={10}
          xxl={8}
          className={Classes.InfoImgPanel}
        >
          <img src={PlayImg} alt="Food" className={Classes.InfoImg} />
        </Col>
      </Row>
    </div>
  );
};

export default Cafe;
