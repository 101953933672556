import Classes from "./Toolbar.module.css";
import {
  EnvironmentOutlined,
  MailOutlined,
  InstagramOutlined,
  InstagramFilled,
  FacebookOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import Aux from "../../hoc/aux/Aux";
import { useState } from "react";
import line_pic from "../../assets/bg/line.jpg";
import logo from "../../assets/logo/logo.png";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Row, Col } from "antd";
import HomeIcon from "../../assets/icons/toolbar/house.png";
import InfoIcon from "../../assets/icons/toolbar/info.png";
import CoffeeIcon from "../../assets/icons/toolbar/coffee.png";
import PartyIcon from "../../assets/icons/toolbar/party.png";
import ContactIcon from "../../assets/icons/toolbar/contact.png";
import AboutIcon from "../../assets/icons/toolbar/about.png";
import AppointmentIcon from "../../assets/icons/toolbar/appointment.png";
import LoginIcon from "../../assets/icons/toolbar/login.png";

const Toolbar = () => {
  const [isFilledIns, setIsFilledIns] = useState(false);
  const [isFilledFB, setIsFilledFB] = useState(false);

  const changeToFilledInsIcon = () => {
    setIsFilledIns(true);
  };

  const changeToOutlinedInsIcon = () => {
    setIsFilledIns(false);
  };

  const changeToFilledFBIcon = () => {
    setIsFilledFB(true);
  };

  const changeToOutlineFBIcon = () => {
    setIsFilledFB(false);
  };

  const IconFrame = (imgSource, iconText, iconColor) => {
    return (
      <div className={Classes.IconFrame}>
        <img src={imgSource} alt="icon" className={Classes.IconImg} />
        <span style={{ color: iconColor }} className={Classes.IconText}>
          {iconText}
        </span>
      </div>
    );
  };

  return (
    <Aux>
      <div className={Classes.TopLayer}>
        <Row>
          <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1} />
          {/* 原来是9 */}
          <Col xs={18} sm={17} md={16} lg={13} xl={10} xxl={9}>
            <div className={Classes.LeftSection}>
              <div className={Classes.LeftPanelSection}>
                <EnvironmentOutlined className={Classes.AddressIcon} />
                <span className={Classes.AddressText}>
                  323 Whitehorse Road, Balwyn
                </span>
              </div>
              <div className={Classes.VerticalLine} />
              <div className={Classes.LeftPanelSection}>
                <MailOutlined className={Classes.EmailIcon} />
                <span className={Classes.AddressText}>
                  info@dancingdandelion.com.au
                </span>
              </div>
            </div>
          </Col>
          <Col xs={0} sm={1} md={2} lg={4} xl={7} xxl={8} />
          <Col xs={6} sm={3} md={4} lg={5} xl={5} xxl={5}>
            <div className={Classes.RightSection}>
              <a
                href="https://www.instagram.com/dancing.dandelion_au/"
                onMouseOver={changeToFilledInsIcon}
                onMouseLeave={changeToOutlinedInsIcon}
              >
                {isFilledIns ? (
                  <InstagramFilled className={Classes.SocialIcon} />
                ) : (
                  <InstagramOutlined className={Classes.SocialIcon} />
                )}
              </a>
              <a
                href="https://www.facebook.com/Dancingdandelion.au"
                onMouseOver={changeToFilledFBIcon}
                onMouseLeave={changeToOutlineFBIcon}
              >
                {isFilledFB ? (
                  <FacebookFilled className={Classes.SocialIcon} />
                ) : (
                  <FacebookOutlined className={Classes.SocialIcon} />
                )}
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <img src={line_pic} alt="line" className={Classes.LinePic} />
      <div className={Classes.SecondLayer}>
        <Row>
          <Col span={1} />
          <Col span={22}>
            <Navbar key={"md"} expand={"md"}>
              <Navbar.Brand href="#">
                <img src={logo} alt="logo" className={Classes.Logo} />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-md`}
                className={Classes.Toogle}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="end"
                style={{ width: "70%" }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1">
                    <Nav.Link href="/" className={Classes.NavLinkH}>
                      {IconFrame(HomeIcon, "Home", "#ffa726")}
                    </Nav.Link>
                    <Nav.Link href="/about" className={Classes.NavLinkH}>
                      {IconFrame(AboutIcon, "About Us", "#8372CF")}
                    </Nav.Link>
                    <Nav.Link href="/info" className={Classes.NavLinkH}>
                      {IconFrame(InfoIcon, "Play Info", "#03a9f4")}
                    </Nav.Link>
                    <Nav.Link href="/cafe" className={Classes.NavLinkH}>
                      {IconFrame(CoffeeIcon, "Cafe", "#f44336")}
                    </Nav.Link>
                    <Nav.Link href="/booking" className={Classes.NavLinkH}>
                      {IconFrame(AppointmentIcon, "Booking", "#ffa726")}
                    </Nav.Link>
                    <Nav.Link href="/party" className={Classes.NavLinkH}>
                      {IconFrame(PartyIcon, "Parties", "#8bc34a")}
                    </Nav.Link>
                    <Nav.Link href="/contact" className={Classes.NavLinkH}>
                      {IconFrame(ContactIcon, "Contact Us", "#03a9f4")}
                    </Nav.Link>
                    <Nav.Link href="/login" className={Classes.NavLinkH}>
                      {IconFrame(LoginIcon, "Log in", "#f44336")}
                    </Nav.Link>
                    {/* the menu below is for small device */}
                    <Nav.Link href="/" className={Classes.MoblieMenu}>
                      {IconFrame(HomeIcon, "Home", "#ffa726")}
                    </Nav.Link>
                    <Nav.Link href="/about" className={Classes.MoblieMenu}>
                      {IconFrame(AboutIcon, "About Us", "#8372CF")}
                    </Nav.Link>
                    <Nav.Link href="/info" className={Classes.MoblieMenu}>
                      {IconFrame(InfoIcon, "Play Info", "#03a9f4")}
                    </Nav.Link>
                    <Nav.Link href="/cafe" className={Classes.MoblieMenu}>
                      {IconFrame(CoffeeIcon, "Cafe", "#f44336")}
                    </Nav.Link>
                    <Nav.Link href="/booking" className={Classes.MoblieMenu}>
                      {IconFrame(AppointmentIcon, "Booking", "#ffa726")}
                    </Nav.Link>
                    <Nav.Link href="/party" className={Classes.MoblieMenu}>
                      {IconFrame(PartyIcon, "Parties", "#8bc34a")}
                    </Nav.Link>
                    <Nav.Link href="/contact" className={Classes.MoblieMenu}>
                      {IconFrame(ContactIcon, "Contact Us", "#03a9f4")}
                    </Nav.Link>
                    <Nav.Link href="/login" className={Classes.MoblieMenu}>
                      {IconFrame(LoginIcon, "Log in", "#f44336")}
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </Col>
        </Row>
        <div className={Classes.BottomGrass} />
      </div>
    </Aux>
  );
};

export default Toolbar;
