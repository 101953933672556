import "antd/dist/reset.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./hoc/layout/Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./containers/home/Home";
import About from "./containers/about/About";
import PlayInfo from "./containers/info/PlayInfo";
import Cafe from "./containers/cafe/Cafe";
import Contact from "./containers/contact/Contact";
import Party from "./containers/party/Party";
import Booking from "./containers/booking/Booking";
import Login from "./containers/login/Login";
import { useSelector } from "react-redux";
import DashboardAdmin from "./containers/dashboard_admin/DashboardAdmin";
import DashboardCustomer from "./containers/dashboard_customer/DashboardCustomer";

function App() {
  let isCustomer = useSelector((state) => state.auth.isCustomer);
  let isAdmin = useSelector((state) => state.auth.isAdmin);

  if (!isAdmin) {
    if (sessionStorage.getItem("isAdmin")) {
      isAdmin = true;
    }
  }

  if (!isCustomer) {
    if (sessionStorage.getItem("isCustomer")) {
      isCustomer = true;
    }
  }
  return (
    <div className="App">
      <Routes>
        {isAdmin ? (
          <Route path="/admin-dashboard/*" element={<DashboardAdmin />} />
        ) : null}
        {isCustomer ? (
          <Route path="/customer-dashboard/*" element={<DashboardCustomer />} />
        ) : null}
      </Routes>
      {isAdmin === false && isCustomer === false ? (
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/party" element={<Party />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cafe" element={<Cafe />} />
            <Route path="/info" element={<PlayInfo />} />
            <Route path="/about" element={<About />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Layout>
      ) : null}
    </div>
  );
}

export default App;
