import Classes from "./About.module.css";
import { Row, Col } from "antd";
import Slider1 from "../../assets/about.jpg";
const About = () => {
  return (
    <div>
      <Row className={Classes.Banner}>
        <Col span={1} />
        <Col span={22}>
          <div className={Classes.Title}>About Us</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={10}
          xl={12}
          xxl={14}
          className={Classes.WelcomePanel}
        >
          <h2>WELCOME TO DANCING DANDELION!</h2>
          <p>
            We are a locally-run indoor play ground and café, situated right in
            the heart of Balwyn on Whitehorse Road. Having children of our own,
            we’ve always been on a search for a place where children can play
            safely while parents can find some rest and be nourished with
            appetising food all in one place. It is our vision to provide
            exactly what parents have been searching for.
          </p>
          <p>
            We are inspired by the humble Dandelion, although thought of as a
            wild flower, it has the ability to grow in the harshest environment,
            bring forth a drop of sunshine with its vibrant yellow flower and
            with its wonderous fluff, spread its happiness everywhere. Dandelion
            symbolises hope, love, growth, goodwill, happiness and healing,
            which is exactly what we aspire to do. We want to provide children
            with a safe space to be exactly who they are, where they can dance
            and be curious, explore and let their imagination run wild. At the
            same time, parents can relax, recharge and heal the meadows of their
            heart for their inner child to run. Afterall, the wellbeing of a
            parent is just as important as, if not more important than, a
            child's.
          </p>
          <p>
            Having an education background, we value and know how important the
            early years are for a child's development. We long to create the
            environment for children to activate their potential, be inspired to
            imagine, be able to spend treasured moments with their parents and
            grow to become unique, kind and respectful individuals.
          </p>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={12}
          xl={10}
          xxl={8}
          className={Classes.WelcomeImgPanel}
        >
          <img
            src={Slider1}
            alt="About us image"
            className={Classes.AboutImg}
          />
        </Col>
      </Row>
    </div>
  );
};

export default About;
