import { Button, Space, Table, Tag } from "antd";
const columns = [
  {
    title: "Booking Id",
    dataIndex: "id",
    key: "id",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Caller Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Booking Type",
    dataIndex: "types",
    key: "types",
    render: (_, { types }) => (
      <>
        {types.map((type) => {
          let color = "#CB5A9A";
          if (type === "Day-to-day booking") {
            color = "#29BBB8";
          }
          return (
            <Tag color={color} key={type}>
              {type.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Party Size",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Quotation price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Request received date",
    dataIndex: "received_date",
    key: "received_date",
  },
];
const data = [
  {
    id: "#1123412421",
    name: "Lisa Wong",
    contact: "0431 000 222",
    types: ["Party booking"],
    size: "3A+10C",
    price: "$260.41",
    received_date: "07 Mar 23",
  },
  {
    id: "#1123412553",
    name: "Jemmy White",
    contact: "0431 111 333",
    types: ["Party booking"],
    size: "1A+3C",
    price: "$54.41",
    received_date: "08 Mar 23",
  },
  {
    id: "#1123412423",
    name: "Belinda Farrer",
    contact: "0431 323 534",
    types: ["Day-to-day booking"],
    size: "5A+12C",
    price: "$324.50",
    received_date: "13 July 23",
  },
  {
    id: "#1123415623",
    name: "Jade Keith",
    contact: "0431 321 361",
    types: ["Party booking"],
    size: "10A+26C",
    price: "$600.3",
    received_date: "15 Mar 23",
  },
  {
    id: "#1123415623",
    name: "Samatha Butters Keith",
    contact: "0431 000 465",
    types: ["Day-to-day booking"],
    size: "2A+4C",
    price: "$60.3",
    received_date: "17 Mar 23",
  },
];

const AllBookingHistories = () => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowClassName={(_, index) => {
        let className = "odd-row";
        if (index % 2 === 1) className = "even-row";
        return className;
      }}
    />
  );
};

export default AllBookingHistories;
