import React from "react";
import {
  FormOutlined,
  SnippetsOutlined,
  ProfileOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { authActions } from "../../store/slices/auth-slice";
import NewBooking from "./new_booking/NewBookings";
import Classes from "./DashboardCustomer.module.css";
import CurrentOrders from "./orders/current/CurrentOrder";
import HistoryOrders from "./orders/history/HistoryOrder";
import Profile from "./profile/Profile";
import Password from "./password/Password";

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: "new",
    icon: <FormOutlined />,
    label: <Link to="/customer-dashboard/new-booking">New Booking</Link>,
  },
  {
    key: "orders",
    icon: <SnippetsOutlined />,
    label: "Orders",
    children: [
      {
        key: "order_current",
        label: <Link to="/customer-dashboard/orders/current">Current</Link>,
      },
      {
        key: "order_history",
        label: <Link to="/customer-dashboard/orders/history">History</Link>,
      },
    ],
  },
  {
    key: "profile",
    icon: <ProfileOutlined />,
    label: <Link to="/customer-dashboard/profile">Profile</Link>,
  },
  {
    key: "password",
    icon: <UnlockOutlined />,
    label: <Link to="/customer-dashboard/password">Reset Password</Link>,
  },
];

const DashboardCustomer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(authActions.logout());
    navigate("/home");
  };

  const handlerSideMenu = (item) => {
    console.log(item.key);
  };

  return (
    <Layout>
      <Header style={{ backgroundColor: "#ffa726" }}>
        <div className={Classes.Logo}> Dancingdandelion</div>
        {/* <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={items1}
        /> */}
      </Header>
      <Layout>
        <Sider width={250} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={items}
            onClick={handlerSideMenu}
          />
          <Button onClick={logout} style={{ width: "100%", top: "-35px" }}>
            Log out
          </Button>
        </Sider>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <Content
            className="site-layout-background"
            style={{
              paddingTop: 24,
            }}
          >
            <Routes>
              <Route path="/new-booking" element={<NewBooking />} />
              <Route path="/orders/current" element={<CurrentOrders />} />
              <Route path="/orders/history" element={<HistoryOrders />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/password" element={<Password />} />
              <Route path="/" element={<NewBooking />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardCustomer;
