import { Table, Space } from "antd";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
  {
    title: "List",
    dataIndex: "list",
    key: "list",
  },
  // {
  //   title: "Tags",
  //   key: "tags",
  //   dataIndex: "tags",
  //   render: (_, { tags }) => (
  //     <>
  //       {tags.map((tag) => {
  //         let color = tag.length > 5 ? "geekblue" : "green";
  //         if (tag === "loser") {
  //           color = "volcano";
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </>
  //   ),
  // },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a>Check</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: "2",
    name: "Jim Green",
    date: "2022-12-25, 9am-11am",
    comment: "Allergy to cilantro",
    // tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    date: "2022-12-25, 3.15pm-4.15pm",
    comment: " ",
    // tags: ["cool", "teacher"],
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};
const HistoryOrders = () => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      style={{ height: "830px" }}
      rowClassName={(_, index) => {
        let className = "odd-row";
        if (index % 2 === 1) className = "even-row";
        return className;
      }}
    />
  );
};

export default HistoryOrders;
