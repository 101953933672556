import { Button, Divider, Form, Input, Select } from "antd";
const { Option } = Select;
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const ContentManagement = (props) => {
  const [form] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case "male":
        form.setFieldsValue({
          note: "Hi, man!",
        });
        return;
      case "female":
        form.setFieldsValue({
          note: "Hi, lady!",
        });
        return;
      case "other":
        form.setFieldsValue({
          note: "Hi there!",
        });
        break;
      default:
    }
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };
  return (
    <>
      <h2>{props.title} Page Edit</h2>
      <Divider />
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item label="Content" name="content">
          <Input.TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{ marginLeft: "40px" }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ContentManagement;
