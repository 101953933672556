import { Badge, Calendar } from "antd";
const getListData = (value) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: "warning",
          content: "13:00 Brian's Booking",
        },
        {
          type: "success",
          content: "12:00 Ella's Birthday",
        },
      ];
      break;
    case 10:
      listData = [
        {
          type: "warning",
          content: "17:30 Jimmy's Booking",
        },
      ];
      break;
    case 15:
      listData = [
        {
          type: "warning",
          content: "13:00 Ted's Booking",
        },
        {
          type: "success",
          content: "15:00 Ian's Birthday",
        },
      ];
      break;
    default:
  }
  return listData || [];
};
const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const UpcomingManagementCalendar = () => {
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Calendar
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
    />
  );
};

export default UpcomingManagementCalendar;
