import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isAuth: false,
  isAdmin: false,
  isCustomer: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logout(state) {
      state.isAdmin = false;
      state.isCustomer = false;
      sessionStorage.clear("isAdmin");
      sessionStorage.clear("isCustomer");
    },
    isAdminLogin(state) {
      state.isAdmin = true;
      sessionStorage.setItem("isAdmin", true);
    },
    isCustomerLogin(state) {
      state.isCustomer = true;
      sessionStorage.setItem("isCustomer", true);
    },
  },
});

// the redux toolkit will automatically create actions behind the scene
export const authActions = authSlice.actions;

export default authSlice;
