import { Space, Table, Tag } from "antd";
const columns = [
  {
    title: "User Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Sign Up Date",
    dataIndex: "date",
    key: "date",
  },

  {
    title: "Mobile Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Member Level",
    key: "level",
    dataIndex: "level",
    render: (_, { level }) => (
      <>
        {level.map((tag) => {
          let color = "";
          if (tag === "bronze") {
            color = "brown";
          } else if (tag === "sliver") {
            color = "silver";
          } else if (tag === "golden") {
            color = "gold";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a>Edit</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: "1",
    name: "John Brown",
    date: "2022-12-24, 17pm-18pm",
    phone: "0411111111",
    email: "brown@gmail.com",
    level: ["bronze"],
  },
  {
    key: "2",
    name: "Jim Green",
    date: "2022-12-24, 17pm-18pm",
    phone: "0422222222",
    email: "green@gmail.com",
    level: ["silver"],
  },
  {
    key: "3",
    name: "Joe Black",
    date: "2022-12-24, 17pm-18pm",
    phone: "0433333333",
    email: "black@gmail.com",
    level: ["golden"],
  },
];

const UserManagement = () => {
  return (
    <Table columns={columns} dataSource={data} style={{ height: "830px" }} />
  );
};

export default UserManagement;
